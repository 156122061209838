import * as types from "../constants";

const initialTicketsHeadCells = [
	{ id: "No", type: "string", primary: true },
	{ id: "CustomerName", type: "string" },
	{ id: "Title", type: "string" },
	{ id: "Status", type: "status-chip" },
	{ id: "Priority", type: "priority-chip" },
	{ id: "LastResponseDate", type: "date" },
	{ id: "LastResponseAuthor", type: "string" },
	{ id: "AssignedContactName", type: "string" },
	{ id: "ContactName", type: "string" },
	{ id: "CreationDate", type: "date" },
	{ id: "Category", type: "string" },
];

export default function ticketsHeadCellsReducer(
	state = initialTicketsHeadCells,
	actions
) {
	switch (actions.type) {
		case types.SET_TICKETS_HEAD_CELLS:
			return [...actions.payload];
		default:
			return state;
	}
}
