import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StylesProvider } from "@material-ui/styles";
import React from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ThemeProvider } from "styled-components";
import Routes from "./routes/Routes";
import maTheme from "./theme";

function App({ theme }) {
	const { i18n } = useTranslation();

	const selectedTheme = maTheme[theme.currentTheme](i18n.language);

	return (
		<React.Fragment>
			<Helmet titleTemplate="%s | Practice" defaultTitle="Practice" />
			<StylesProvider injectFirst>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<MuiThemeProvider theme={selectedTheme}>
						<ThemeProvider theme={selectedTheme}>
							<Routes />
						</ThemeProvider>
					</MuiThemeProvider>
				</MuiPickersUtilsProvider>
			</StylesProvider>
		</React.Fragment>
	);
}

export default connect((store) => ({ theme: store.themeReducer }))(App);
