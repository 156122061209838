import * as types from "../constants";

export default function teamFilterReducer(state = [], actions) {
	switch (actions.type) {
		case types.SET_TEAM_FILTER:
			return [...actions.payload];
		default:
			return state;
	}

	// SET_TEAM_FILTER : on l'appel avec le useDispatch
}
