import * as types from "../constants";

export default function customerTicketFilterReducer(state = {}, actions) {
	switch (actions.type) {
		case types.SET_CUSTOMER_TICKET_FILTER:
			return actions.payload;
		default:
			return state;
	}
}
