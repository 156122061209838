import * as types from "../constants";

const initialTasksProcessPanelCells = [
	{ id: "ProcessFolderCode", active: !!1, type: "string", primary: true },
	{ id: "ProcessFolderService", active: !!1, type: "string", primary: true },
	{ id: "ProcessFolderTask", active: !!1, type: "string", primary: true },
	{ id: "ProcessFolderStep", active: !!1, type: "string" },
	{ id: "ProcessDescription", active: !!1, type: "string" },
	{ id: "ProcessStepBeginDate", active: !!1, type: "date" },
	{ id: "ProcessStepEndDate", active: !!1, type: "date" },
	{ id: "ProcessStepStatus", active: !!1, type: "string" },
	{ id: "ProcessStepInitialQuantity", active: !!1, type: "string" },
	{ id: "ProcessStepWorkedQuantity", active: !!1, type: "string" },
];

export default function tasksProcessPanelCellsReducer(state = initialTasksProcessPanelCells, actions) {
	switch (actions.type) {
		case types.SET_TASKPROCESSPANEL_CELLS:
			return [...actions.payload];
		default:
			return state;
	}
}
