// L'URL de l'API peut être modifiée dans le fichier public/config.js
export const API_URL = window.config.apiUrl;

// Vérification de l'URL du proxy en production
// Sauf cas extrême, l'URL ne DOIT PAS pointer sur localhost
if (
	API_URL.indexOf("localhost") > -1 &&
	location.hostname !== "localhost" &&
	sessionStorage.getItem("localhost") !== "true"
) {
	sessionStorage.setItem(
		"localhost",
		confirm(
			'L\'API est configuré en local ! Cela peut provoquer des erreurs réseau.\nCliquez sur "OK" pour ne plus voir ce message.'
		)
	);
}

const KEY = "API_TOKEN";

export const setToken = (token) => localStorage.setItem(KEY, token);

export const getToken = () => localStorage.getItem(KEY);

export const isLoggedIn = () =>
	getToken() !== "" && getToken() !== null && getToken() !== undefined;

export const removeToken = () => localStorage.removeItem(KEY);

export const parseJwt = (token) => {
	// Récupération du token automatique
	if (typeof token !== "string") token = getToken();

	// Si la personne est connecté
	if (token !== null) {
		var base64Url = token.split(".")[1];
		var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
		var jsonPayload = decodeURIComponent(
			window
				.atob(base64)
				.split("")
				.map(function (c) {
					return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
				})
				.join("")
		);
		return JSON.parse(jsonPayload);
	} else return null;
};

// Permet d'afficher ou non des catégories dans le menu principal
export const userMenuFilter = (menu) => {
	// SUPER IMPORTANT !
	// Si personne n'est connecté, on ne peux pas récupérer de token, ni
	// de userMenu ! On renvois alors vrai partout de manière temporaire
	// Si on renvoyais false, la page d'acceuil ne serais même pas visible !
	if (!isLoggedIn()) return true;

	// On récupère le userMenu stocké dans le token
	// Par defaut, si l'API est en localhost, on affiche le menu
	const userMenu = parseJwt().Menu;
	const defaultValue = window.config.apiUrl.includes("localhost");
	const debugMenu = false;

	switch (menu.id) {
		case "ticket":
		case "tickets":
			if (debugMenu)
				console.log({
					defaultValue,
					id: menu.id,
					isVisible: userMenu?.ZenTickets,
				});
			return (userMenu?.ZenTickets || userMenu?.PracticeTickets) ?? defaultValue;
		case "task":
		case "tasks":
			if (debugMenu)
				console.log({
					defaultValue,
					id: menu.id,
					isVisible: userMenu?.ZenTasks,
				});
			return (userMenu?.ZenTasks || userMenu?.PracticeTasks) ?? defaultValue;
		case "recurrent-invoices":
			if (debugMenu)
				console.log({
					defaultValue,
					id: menu.id,
					isVisible: userMenu?.ZenRecurrentInvoices,
				});
			return (
				(userMenu?.ZenRecurrentInvoices || userMenu?.PracticeRecurrentInvoices) ??
				defaultValue
			);
		case "invoice-credit-notes":
			if (debugMenu)
				console.log({
					defaultValue,
					id: menu.id,
					isVisible: userMenu?.ZenInvoiceAndCreditMemos,
				});
			return (
				(userMenu?.ZenInvoiceAndCreditMemos ||
					userMenu?.PracticeInvoiceAndCreditMemos) ??
				defaultValue
			);
		case "customer-account":
			if (debugMenu)
				console.log({
					defaultValue,
					id: menu.id,
					isVisible: userMenu?.ZenCustomerAccountEntries,
				});
			return (
				(userMenu?.ZenCustomerAccountEntries || userMenu?.PracticeCustomerAccount) ??
				defaultValue
			);
		case "contracts-menu-title":
			if (debugMenu)
				console.log({
					defaultValue,
					id: menu.id,
					isVisible: userMenu?.ZenContracts,
				});
			return (userMenu?.ZenContracts || userMenu?.PracticeContracts) ?? defaultValue;
		case "infra-access":
			if (debugMenu)
				console.log({
					defaultValue,
					id: menu.id,
					isVisible: userMenu?.PracticeInfraAccess,
				});
			return userMenu?.PracticeInfraAccess || defaultValue;

		case "timesheet-menu-title":
			if (debugMenu)
				console.log({
					defaultValue,
					id: menu.id,
					isVisible: userMenu?.PracticeContributorCalendars,
				});
			return userMenu?.PracticeContributorCalendars || defaultValue;
		case "vacationValidationDetail":
		case "leave":
			if (debugMenu)
				console.log({
					defaultValue,
					id: menu.id,
					isVisible: userMenu?.PracticeVacationsRequests,
				});
			return userMenu?.PracticeVacationsRequests || defaultValue;

		// Les routes dont ont aura toujours besoins
		case "umbrella":
		case "profile":
			if (debugMenu) console.log({ id: menu.id });
			return true;

		default:
			console.warn(
				`Menu with id: "${menu.id}" was not found when applying userMenuFilter. By default, it will be shown.`
			);
			return true;
	}
};
