import * as types from "../constants";

export default function customerTaskFilterReducer(state = "", actions) {
	switch (actions.type) {
		case types.SET_CUSTOMER_TASK_FILTER:
			return actions.payload;
		default:
			return state;
	}
}
