import * as types from "../constants";

export default function congesRttReducer(state = ["RTT"], action) {
	switch (action.type) {
		case types.SET_CONGES_RTT:
			return action.payload;
		default:
			return state;
	}
}
