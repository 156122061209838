import {
	Dashboard as DashboardIcon,
	Feedback as FeedbackIcon,
	Lock as LockIcon,
	ConfirmationNumber as TicketIcon,
} from "@material-ui/icons";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import EventNoteIcon from "@material-ui/icons/EventNote";
import { userMenuFilter } from "api";
import React from "react";
import { User, Users } from "react-feather";
import async from "../components/Async";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faClipboardCheck, faFileSignature } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
library.add(faFileSignature, faClipboardCheck);

// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));
const PageNetworkError = async(() => import("../pages/auth/PageNetworkError"));

// Pages components
const Profile = async(() => import("../pages/pages/Profile"));
const Umbrella = async(() => import("../pages/pages/Umbrella"));
const InfraAccessList = async(() => import("../pages/pages/InfraAccessList"));
const TicketsList = async(() => import("../pages/pages/TicketsList"));
const TicketDetails = async(() => import("../pages/pages/TicketDetails"));
const TicketsCategory = async(() => import("../pages/pages/TicketsCategory"));
const Tasks = async(() => import("../pages/pages/TasksList"));
const TaskDetails = async(() => import("../pages/pages/TaskDetails"));
// const RecurrentInvoices = async(() => import('../pages/pages/RecurrentInvoicesList'));
// const InvoiceCreditNotes = async(() => import('../pages/pages/InvoiceCreditNotes'));
// const CustomerAccountEntryList = async(() => import('../pages/pages/CustomerAccountEntryList'));
// const Contracts = async(() => import('../pages/pages/Contracts')); // Affichage composant 'contrat' dans menu lateral
const TimeSheet = async(() => import("../pages/pages/TimeSheet"));

const AbscenceTime = async(() => import("../pages/pages/AbscenceTime"));
const VacationRequest = async(() => import("../pages/pages/VacationRequest"));
const VacationValidation = async(() => import("../pages/pages/VacationValidation"));
const VacationValidationDetail = async(() =>
	import("../pages/pages/VacationValidationDetail")
);

const authRoutes = {
	id: "Auth",
	path: "/auth",
	icon: <Users />,
	children: [
		{
			path: "/auth/login",
			name: "Connexion",
			component: SignIn,
		},
		{
			path: "/auth/reset-password",
			name: "Reset Password",
			component: ResetPassword,
		},
		{
			path: "/404",
			name: "Page non trouvée",
			component: Page404,
		},
		{
			path: "/500",
			name: "Erreur interne",
			component: Page500,
		},
		{
			path: "/networkerror",
			name: "Erreur réseau",
			component: PageNetworkError,
		},
	],
};

const profileRoutes = {
	id: "profile",
	path: "/profil",
	icon: <User />,
	component: Profile,
	children: null,
};

const umbrellaRoutes = {
	id: "umbrella",
	path: "/",
	icon: <DashboardIcon />,
	component: Umbrella,
	children: null,
	isHome: true,
};

const infraAccessList = {
	id: "infra-access",
	path: "/infra-access",
	icon: <LockIcon />,
	component: InfraAccessList,
	children: null,
};

const ticketsList = {
	id: "tickets",
	path: "/tickets",
	icon: <TicketIcon />,
	children: [
		{
			path: "/tickets/<>closed",
			name: "ticket-not-closed",
			component: TicketsList,
		},
		{
			path: "/tickets/draft copy",
			name: "ticket-draft_copy",
			component: TicketsList,
		},
		{
			path: "/tickets/open",
			name: "ticket-open",
			component: TicketsList,
		},
		{
			path: "/tickets/pending",
			name: "ticket-pending",
			component: TicketsList,
		},
		{
			path: "/tickets/in progress",
			name: "ticket-in_progress",
			component: TicketsList,
		},
		{
			path: "/tickets/closed",
			name: "ticket-closed",
			component: TicketsList,
		},
		// {
		// 	path: "/tickets",
		// 	name: "tickets-all",
		// 	component: TicketsList,
		// },
		{
			path: "/tickets-category",
			name: "tickets-category",
			component: TicketsCategory,
		},
	],
};

const ticketDetails = {
	id: "ticket",
	path: "/tickets/:no",
	icon: <TicketIcon />,
	component: TicketDetails,
	children: null,
};

const tasksList = {
	id: "tasks",
	path: "/tasks",
	icon: <FeedbackIcon />,
	component: Tasks,
	children: [
		{
			path: "/tasks/non-validated",
			name: "task-non_validated",
			component: Tasks,
		},
		{
			path: "/tasks/initialized",
			name: "task-initialized",
			component: Tasks,
		},
		{
			path: "/tasks/signature",
			name: "task-signature",
			component: Tasks,
		},
		{
			path: "/tasks/in progress",
			name: "task-in_progress",
			component: Tasks,
		},
		{
			path: "/tasks/delivered in test",
			name: "task-delivered_in_test",
			component: Tasks,
		},
		{
			path: "/tasks/customer validated",
			name: "task-customer_validated",
			component: Tasks,
		},
		{
			path: "/tasks/in production",
			name: "task-in_production",
			component: Tasks,
		},
		{
			path: "/tasks/denied",
			name: "task-denied",
			component: Tasks,
		},
		{
			path: "/tasks/billable",
			name: "tasks-billable",
			component: Tasks,
		},
		// {
		// 	path: "/tasks",
		// 	name: "tasks-all",
		// 	component: Tasks,
		// },
	],
};

const taskDetails = {
	id: "task",
	path: "/tasks",
	icon: <FeedbackIcon />,
	component: TaskDetails,
	children: null,
	open: true,
};

// const recurrentInvoices = {
// 	id: 'recurrent-invoices',
// 	path: '/recurrent-invoices',
// 	icon: <StorageIcon />,
// 	component: RecurrentInvoices,
// 	children: null,
// };

// const invoiceCreditNotes = {
// 	id: 'invoice-credit-notes',
// 	path: '/invoice-credit-notes',
// 	icon: <ReceiptIcon />,
// 	component: InvoiceCreditNotes,
// 	children: null,
// };

// const customerAccountEntryList = {
// 	id: 'customer-account',
// 	path: '/customer-account',
// 	icon: <ReceiptIcon />,
// 	component: CustomerAccountEntryList,
// 	children: null,
// };

// const contracts = { // Affichage composant 'contrat' dans menu lateral
// 	id: 'contracts-menu-title',
// 	//path: '/contracts',
// 	icon: <FontAwesomeIcon icon={faFileSignature} />,
// 	component: Contracts,
// 	children: null,
// };

const timeSheet = {
	id: "timesheet-menu-title",
	path: "/timesheet",
	icon: <EventNoteIcon />,
	component: TimeSheet,
	children: null,
};

const leave = {
	id: "leave",
	path: "/leave",
	icon: <BeachAccessIcon />,
	children: [
		{
			name: "abscenceTime",
			path: "/leave/abscence-time",
			component: AbscenceTime,
		},
		// {
		// 	name: "vacationRequest",
		// 	path: "/leave/vacation-request",
		// 	component: VacationRequest,
		// },
		// {
		// 	name: "vacationValidation",
		// 	path: "/leave/vacation-validation",
		// 	component: VacationValidation,
		// },
	],
};

const vacationValidationDetail = {
	id: "vacationValidationDetail",
	path: "/leave/vacation-validation-detail/:no",
	icon: <FontAwesomeIcon icon={faClipboardCheck} />,
	component: VacationValidationDetail,
	children: null,
};

export const dashboard = [
	umbrellaRoutes,
	profileRoutes,
	infraAccessList,
	ticketsList,
	ticketDetails,
	tasksList,
	taskDetails,
	// recurrentInvoices,
	// invoiceCreditNotes,
	// customerAccountEntryList,
	timeSheet,
	leave,
	vacationValidationDetail,
	// contracts, // Affichage composant 'contrat' dans menu lateral
].filter(userMenuFilter);

export const auth = [authRoutes];

export default [
	umbrellaRoutes,
	infraAccessList,
	ticketsList,
	tasksList,
	// recurrentInvoices,
	// invoiceCreditNotes,
	// customerAccountEntryList,
	timeSheet,
	leave,
	//contracts,  // desavtivation de l'affichage du module contrat sur le menu laterale
].filter(userMenuFilter);
