export const SET_THEME = "SET_THEME";
export const SET_TASKS_HEAD_CELLS = "SET_TASKS_HEAD_CELLS";
export const SET_TICKETS_HEAD_CELLS = "SET_TICKETS_HEAD_CELLS";
export const SET_TEAM_FILTER = "SET_TEAM_FILTER";
export const SET_ROWS_PER_PAGE = "SET_ROWS_PER_PAGE";
export const SET_TASKPROCESSPANEL_CELLS = "SET_TASKPROCESSPANEL_CELLS";
export const SET_CONGES_RTT = "SET_CONGES_RTT";
// Filtres de la liste des tâches
export const SET_FOLDER_CODE_FILTER = "SET_FOLDER_CODE_FILTER";
export const SET_CUSTOMER_TASK_FILTER = "SET_CUSTOMER_TASK_FILTER";
export const SET_COMPANY_TASK_FILTER = "SET_COMPANY_TASK_FILTER";
// Historique des requêtes visible dans l'assistant d'aide
export const SET_REQUEST_HISTORY = "SET_REQUEST_HISTORY";
export const SET_CUSTOMER_TICKET_FILTER = "SET_CUSTOMER_TICKET_FILTER";
