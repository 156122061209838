import * as types from "../constants";

const initialRowsPerPage = 25;

export default function rowsPerPageReducer(state = initialRowsPerPage, actions) {
	switch (actions.type) {
		case types.SET_ROWS_PER_PAGE:
			return actions.payload;
		default:
			return state;
	}
}
