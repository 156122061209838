import { createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "../reducers/index";

// Redux-Persit est utilisé pour que les objets redux soient 'persistés' et 'rehydratés'
// L'import storage, utilisé pour la configuration, indique que c'est le local storage qui recevra l'objet persitsté
// https://www.npmjs.com/package/redux-persist#storage-engines

const persistConfig = {
	key: "root",
	storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export const persistor = persistStore(store);

export default store;
