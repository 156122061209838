import { combineReducers } from "redux";

import practiceCompanyTaskFilterReducer from "./companyTaskFilterReducer";
import practiceCongesRttReducer from "./congesRttReducer";
import customerTaskFilterReducer from "./customerTaskFilterReducer";
import practiceCustomerTicketFilterReducer from "./customerTicketFilterReducer";
import folderCodeFilterReducer from "./folderCodeFilterReducer";
import practiceRowsPerPageReducer from "./rowsPerPageReducer";
import practiceTaskProcessPanelCellsReducer from "./taskProcessPanelCellsReducer";
import practiceTasksHeadCellsReducer from "./tasksHeadCellsReducer";
import practiceTeamFilterReducer from "./teamFilterReducer";
import themeReducer from "./themeReducers";
import practiceTicketsHeadCellsReducer from "./ticketsHeadCellsReducer";

// Le prefixe "practice" est placé devant le nom des reducers afin d'éviter des conflits
// avec Zen, si il tourne sur la même machine.
// Les states redux persistent ici dans le local storage et doivent donc être distincts.

// On récupere les états avec un "useSelector" qui désigne le reducer avec son prefixe.
// Par ex: 	const rowsPerPage = useSelector((state)=>state.practiceRowsPerPageReducer);

export default combineReducers({
	themeReducer,
	practiceTasksHeadCellsReducer,
	practiceTicketsHeadCellsReducer,
	practiceTeamFilterReducer,
	practiceRowsPerPageReducer,
	practiceTaskProcessPanelCellsReducer,
	practiceCongesRttReducer, // appel du file congesRttReducer.js
	folderCodeFilterReducer,
	customerTaskFilterReducer,
	practiceCustomerTicketFilterReducer,
	practiceCompanyTaskFilterReducer,
});
