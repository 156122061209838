import * as types from "../constants";

export default function folderCodeFilterReducer(
	state = "",
	actions
) {
	switch (actions.type) {
		case types.SET_FOLDER_CODE_FILTER:
			return actions.payload;
		default:
			return state;
	}
}
